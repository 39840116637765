import React from 'react';
import GlobalController from './configs/GlobalController';

function App() {
  return (
    <GlobalController />
  );
}

export default App;
