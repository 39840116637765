import React, { useState, useEffect, useRef } from 'react';
import '../styles/dashboardStyle.css';
import Navbar from '../components/navbar';
import Hero from '../components/hero';
import About from '../components/about';
import Projects from '../components/projects';
import References from '../components/references';
import Contact from '../components/contact';

export default function Dashboard() {
  const [showContact, setShowContact] = useState(false);
  const [showScrollDown, setShowScrollDown] = useState(true); // Controls scroll-down visibility
  const containerRef = useRef(null);
  const aboutRef = useRef(null); // Ref for About section

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (!container) return;

      const totalHeight = container.scrollHeight;
      const scrollPosition = container.scrollTop + container.clientHeight;

      console.log('Scroll Position:', scrollPosition);

      if (scrollPosition >= totalHeight / 2) {
        setShowContact(true);
      } else {
        setShowContact(false);
      }
    };

    const container = containerRef.current;
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Intersection observer for About section visibility
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setShowScrollDown(entry.intersectionRatio < 0.4); // Show button if less than 40% in view
      },
      {
        threshold: [0.4], // Trigger when 40% of About is in view
      }
    );

    if (aboutRef.current) {
      observer.observe(aboutRef.current);
    }

    return () => {
      if (aboutRef.current) {
        observer.unobserve(aboutRef.current);
      }
    };
  }, []);

  const [isLoaded, setIsLoaded] = useState(false);
  const [heroLoaded, setHeroLoaded] = useState(false);
  const [contactLoaded, setContactLoaded] = useState(false);

  useEffect(() => {
    if (heroLoaded) {
      setIsLoaded(true);
    }
  }, [heroLoaded]);

  const [year, setYear] = useState(2024);

  useEffect(() => {
    try {
      const currentYear = new Date().getFullYear();
      setYear(currentYear);
    } catch {
      setYear(2024);
    }
  }, []);

  return (
    <div className="container" ref={containerRef}>
      {!isLoaded && (
        <div className="loader-container">
          <div className="loaderball"></div>
        </div>
      )}
      <div className="scroller">
        <Navbar />
        <div className="scrollings">
          <Hero onLoaded={() => setHeroLoaded(true)} showScrollDown={showScrollDown} />
          <div ref={aboutRef}> {/* Wrap About with a ref for the observer */}
            <About />
          </div>
          <Projects />
          <References />
          <Contact onLoaded={() => setContactLoaded(true)} />
          <div className="footer">
            <p>&copy; {year} Ziqian Wang | All Rights Reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
}
