import React, { useRef, useEffect, useState } from 'react';
import '../styles/heroStyle.css';
import videoSource from '../assets/hero/animated.mp4';

export default function Video({ onVideoLoaded }) {
  const videoRef = useRef(null);
  const [isLoadedEnough, setIsLoadedEnough] = useState(false);

  useEffect(() => {
    const video = videoRef.current;

    // Check if video can autoplay
    const checkAutoplay = async () => {
      try {
        await video.play();
        // Video can autoplay, so set the loaded state
        setIsLoadedEnough(true);
        if (onVideoLoaded) {
          onVideoLoaded();
        }
      } catch (error) {
        // Autoplay failed, pause the video and set the first frame as a static image
        video.pause();
        video.currentTime = 0;
        setIsLoadedEnough(true); // Still set as loaded to notify the parent
        if (onVideoLoaded) {
          onVideoLoaded();
        }
      }
    };

    checkAutoplay();

    const handlePlayback = () => {
      const buffered = video.buffered;

      if (buffered.length > 0) {
        const bufferEnd = buffered.end(0);

        // Check if there's at least 0.1 seconds buffered
        if (bufferEnd >= 0.1 && !isLoadedEnough) {
          setIsLoadedEnough(true);
          if (onVideoLoaded) {
            onVideoLoaded();
          }
        }
      }
    };

    video.addEventListener('timeupdate', handlePlayback);

    return () => {
      video.removeEventListener('timeupdate', handlePlayback);
    };
  }, [onVideoLoaded, isLoadedEnough]);

  return (
    <video
      ref={videoRef}
      muted
      loop
      playsInline
      preload="auto"
    >
      <source src={videoSource} type="video/mp4" />
    </video>
  );
}
