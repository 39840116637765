import React from 'react';
import '../styles/navbarStyle.css';
import logo from '../assets/hero/hero.svg';
export default function Navbar({ placeholder = false }) {
  
  const style = {
    opacity: placeholder ? 0 : 1,
    pointerEvents: placeholder ? 'none' : 'auto',
    position: placeholder ? 'static' : 'fixed',
    marginTop: placeholder ? '-0rem' : '0',
  };

  return (
    <div className="navbar" style={style} aria-hidden={placeholder}>
      <a className="navbar-left" href="#hero" title="scroll to top">
        <img src={logo} alt={placeholder ? "" : "logo"} className="logo" />
        <h1>Ziqian Wang</h1>
      </a>
      <div className="navbar-right">
        <a href="#about" tabIndex={placeholder ? -1 : 0} title = "scroll to about section">About.</a>
        <a href="#projects" tabIndex={placeholder ? -1 : 0} title = "scroll to projects section">Projects.</a>
        <a href="#contact" tabIndex={placeholder ? -1 : 0} title = "scroll to contact section">Contact.</a>
      </div>
    </div>
  );
}
