import React, { useState } from 'react';
import '../styles/aboutStyle.css';

// Import each image
import figmaImg from '../assets/about/design/figma.png';
import wixImg from '../assets/about/design/wix.png';
import webflowImg from '../assets/about/design/webflow.png';
import illustratorImg from '../assets/about/design/ai.png';
import photoshopImg from '../assets/about/design/ps.png';
import afterEffectsImg from '../assets/about/design/ae.png';
import htmlImg from '../assets/about/web-dev/html.png';
import cssImg from '../assets/about/web-dev/css.png';
import jsImg from '../assets/about/web-dev/js.png';
import tsImg from '../assets/about/web-dev/ts.png';
import reactImg from '../assets/about/web-dev/react.png';
import nextImg from '../assets/about/web-dev/next.png';
import vueImg from '../assets/about/web-dev/vue.png';
import swiftImg from '../assets/about/mobile-dev/swift.png';
import pythonImg from '../assets/about/backend/python.png';
import javaImg from '../assets/about/backend/java.png';
import flaskImg from '../assets/about/backend/flask.png';
import dockerImg from '../assets/about/backend/docker.png';
import awsImg from '../assets/about/backend/aws.png';
import firebaseImg from '../assets/about/backend/firebase.png';

export default function About() {
  const toolkit = [
    {
      category: 'Design',
      tools: [
        { name: 'Figma', imgSrc: figmaImg },
        { name: 'Wix', imgSrc: wixImg },
        { name: 'Webflow', imgSrc: webflowImg },
        { name: 'Illustrator', imgSrc: illustratorImg },
        { name: 'Photoshop', imgSrc: photoshopImg },
        { name: 'After Effects', imgSrc: afterEffectsImg }
      ]
    },
    {
      category: 'Web Dev',
      tools: [
        { name: 'HTML', imgSrc: htmlImg },
        { name: 'CSS', imgSrc: cssImg },
        { name: 'JavaScript', imgSrc: jsImg },
        { name: 'TypeScript', imgSrc: tsImg },
        { name: 'React', imgSrc: reactImg },
        { name: 'Next.js', imgSrc: nextImg },
        { name: 'Vue', imgSrc: vueImg }
      ]
    },
    {
      category: 'Mobile Dev',
      tools: [
        { name: 'JavaScript', imgSrc: jsImg },
        { name: 'TypeScript', imgSrc: tsImg },
        { name: 'React Native', imgSrc: reactImg },
        { name: 'Swift', imgSrc: swiftImg }
      ]
    },
    {
      category: 'Backend',
      tools: [
        { name: 'Python', imgSrc: pythonImg },
        { name: 'JavaScript', imgSrc: jsImg },
        { name: 'TypeScript', imgSrc: tsImg },
        { name: 'Java', imgSrc: javaImg },
        { name: 'Flask', imgSrc: flaskImg },
        { name: 'Docker', imgSrc: dockerImg },
        { name: 'AWS', imgSrc: awsImg },
        { name: 'Firebase', imgSrc: firebaseImg }
      ]
    }
  ];

  return (
    <div id="about">
      <div className="about-content">
        <h2>About.</h2>
        <p>
          Ziqian Wang <span className="small-text">(Zee-Chen Wong)</span> is a <strong>designer + developer</strong> who blends the mindfulness of design with the technology of software development to craft human-centered digital experiences. With expertise in frontend frameworks like React and React Native, alongside a toolkit of programming languages, he brings unique and captivating creations to the digital realm.
        </p>
      </div>

      <div className="toolkit">
        <h3>His Toolbox</h3>
        <div className="tools-list">
          {toolkit.map((toolCategory, index) => (
            <div className="tool-li" key={index}>
              <h4>{toolCategory.category}.</h4>
              <ul>
                {toolCategory.tools.map((tool, toolIndex) => (
                  <li key={toolIndex}>
                    <img src={tool.imgSrc} alt={tool.name} />
                    {tool.name}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
