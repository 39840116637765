import React from 'react';
import '../styles/projectsStyle.css';

// Import each image
import munchMapImg from '../assets/projects/munchMap.png';
import bubbleTalkImg from '../assets/projects/bubbleTalk.png';
import ondoImg from '../assets/projects/ondo.png';
import wayImg from '../assets/projects/way.png';
import kakuNomiImg from '../assets/projects/kakunomi.png';
import sakamotoImg from '../assets/projects/sakamoto.png';
import chadaImg from '../assets/projects/chada.png';
import ursaworksImg from '../assets/projects/ursaworks.png';
import martiniCatalogImg from '../assets/projects/martini.png';
//icon
import downTurnArrowIcon from '../assets/projects/icon/down-turn-arrow.svg';

const projectData = [
  {
    name: 'Munch Map',
    services: 'UI/UX Design, Prototype Dev',
    category: 'Mobile App',
    image: munchMapImg,
    link: 'https://www.behance.net/gallery/200693597/Munch-Map',
    preview: true
  },
  {
    name: 'Bubble Talk',
    services: 'UI/UX Design',
    category: 'AR App',
    image: bubbleTalkImg,
    link: 'https://jeffreyw2021.github.io/bubbleTalk/',
    preview: true
  },
  {
    name: 'Ondō',
    services: 'UI/UX Design',
    category: 'Mobile App',
    image: ondoImg,
    link: 'https://www.behance.net/gallery/202179093/ONDO-AI-Powered-Sympathetic-Lofi-Music-Generator',
    preview: true
  },
  {
    name: 'WAY',
    services: 'UI/UX Design, Prototype Dev',
    category: 'Mobile App',
    image: wayImg,
    link: '',
    preview: false
  },
  {
    name: 'Ursaworks',
    services: 'UI/UX Design, Prototype Dev',
    category: 'Website',
    image: ursaworksImg,
    link: '',
    preview: false
  },
  {
    name: 'Kaku Nomi',
    services: 'UI/UX Design',
    category: 'Mobile App',
    image: kakuNomiImg,
    link: '',
    preview: false
  },
  {
    name: 'Chada',
    services: 'UI/UX Design, UX Research',
    category: 'Website',
    image: chadaImg,
    link: '',
    preview: false
  },
  // {
  //   name: 'Sakamoto',
  //   services: 'UI/UX Design',
  //   category: 'Game UI',
  //   image: sakamotoImg,
  //   link: '',
  //   preview: false
  // },
  // {
  //   name: 'The Martini Catalog',
  //   services: 'UI/UX Design',
  //   category: 'Website',
  //   image: martiniCatalogImg,
  //   link: '',
  //   preview: false
  // }
];

export default function Projects() {
  return (
    <div id="projects">
      <div className="projects-header">
        <h2>Projects.</h2>
        <img src={downTurnArrowIcon} alt="Scroll Down" />
      </div>
      <div className="project-infos"></div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Services</th>
            <th>Category</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {projectData.map((project, index) => (
            <tr key={index}>
              <td>
                <a
                  href={project.link || "#"}
                  onClick={(e) => {
                    if (!project.link) e.preventDefault();
                  }}
                >
                  {project.name}
                </a>
              </td>
              <td>
                <a
                  href={project.link || "#"}
                  onClick={(e) => {
                    if (!project.link) e.preventDefault();
                  }}
                >
                  {project.services}
                </a>
              </td>
              <td>
                <a
                  href={project.link || "#"}
                  onClick={(e) => {
                    if (!project.link) e.preventDefault();
                  }}
                >
                  {project.category}
                </a>
              </td>
              <td>
                {project.image && (
                  <a
                    href={project.link || "#"}
                    onClick={(e) => {
                      if (!project.link) e.preventDefault();
                    }}
                  >
                    <img src={project.image} alt={project.name} />
                  </a>
                )}
              </td>
              <td className={project.preview ? "preview" : ""}>
                <a
                  href={project.link || "#"}
                  onClick={(e) => {
                    if (!project.link) e.preventDefault();
                  }}
                >
                  <p>{project.preview ? "detail for preview" : "contact for detail"}</p>
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
